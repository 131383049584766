import React, { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { Mic, MicOff, Volume2, VolumeX } from 'lucide-react';
import './AudioControls.css';

const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

const AudioControls = ({ socket, roomId, playerName }) => {
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        await client.join(process.env.REACT_APP_AGORA_APP_ID, roomId, null, playerName);
        console.log('Joined to Agora channel successfully');
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        await client.publish(audioTrack);
        setLocalAudioTrack(audioTrack);

        client.on('user-published', async (user, mediaType) => {
          await client.subscribe(user, mediaType);
          if (mediaType === 'audio') {
            user.audioTrack.play();
          }
        });
      } catch (error) {
        console.error('Error joining Agora channel:', error);
      }
    };

    init();

    return () => {
      if (localAudioTrack) {
        localAudioTrack.close();
      }
      client.leave();
    };
  }, [roomId, playerName]);

  const toggleMute = () => {
    if (localAudioTrack) {
      setIsMuted(!isMuted);
      localAudioTrack.setEnabled(isMuted);
      socket.emit('audioStateChange', { roomId, isMuted: isMuted });
    }
  };

  const toggleAudio = () => {
    setIsAudioEnabled(!isAudioEnabled);
    client.remoteUsers.forEach(user => {
      if (user.audioTrack) {
        isAudioEnabled ? user.audioTrack.stop() : user.audioTrack.play();
      }
    });
  };

  return (
    <div className="audio-controls">
      <button onClick={toggleMute} className="icon-button">
        {isMuted ? <MicOff size={24} /> : <Mic size={24} />}
      </button>
      <button onClick={toggleAudio} className="icon-button">
        {isAudioEnabled ? <Volume2 size={24} /> : <VolumeX size={24} />}
      </button>
    </div>
  );
};

export default AudioControls;