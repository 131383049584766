import React from 'react';
import '../theme.css';

function Scoreboard({ scores }) {
  return (
    <div className="scoreboard">
      {Object.entries(scores).map(([player, score]) => (
        <div key={player} className="player-score">
          <span className="player-name">{player}</span>
          <span className="score-value">{score}</span>
        </div>
      ))}
    </div>
  );
}

export default Scoreboard;