import React, { useState, useEffect } from 'react';
import Scoreboard from './Scoreboard';
import PlayerPanel from './PlayerPanel';
import GameOverScreen from './GameOverScreen';
import AudioControls from './AudioControls';
import '../theme.css';

function Game({ socket, roomId, playerName, isHost }) {
  const [players, setPlayers] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState('');
  const [question, setQuestion] = useState('');
  const [scores, setScores] = useState({});
  const [streak, setStreak] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameState, setGameState] = useState(null);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState(null);
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [previousQuestionInfo, setPreviousQuestionInfo] = useState(null);
  const [popupDuration, setPopupDuration] = useState(0);

  const [gameOverData, setGameOverData] = useState(null);

  const correctMessages = [
    "Awesome!", "Brilliant!", "Fire!",
    "Correct-amundo!", "You're a math wizard!", "Right as rain!", "Bingo!"
  ];

  useEffect(() => {
    if (!socket) return;

    socket.on('gameUpdate', (updatedGameState) => {
      console.log('Received game update:', updatedGameState);
      setGameState(updatedGameState);
      if (updatedGameState.previousQuestionInfo) {
        setPreviousQuestionInfo(updatedGameState.previousQuestionInfo);
        setShowResultPopup(true);
        const timeoutDuration = updatedGameState.previousQuestionInfo.isCorrect ? 1500 : 3000;
        setPopupDuration(timeoutDuration);
        setTimeout(() => {
          setShowResultPopup(false);
        }, timeoutDuration);
      }
    });

    /*
    //Below is to show Game over with winner of current game. Use alternate for Streak leaderboard
    socket.on('gameOver', ({ winner, finalScores,previousQuestionInfo }) => {
      console.log('Game Over!', winner, finalScores);
      setGameState(prevState => ({
        ...prevState,
        isGameOver: true,
        winner: winner,
        finalScores: finalScores
      }));
      if (previousQuestionInfo) {
        setPreviousQuestionInfo(previousQuestionInfo);
        setShowResultPopup(true);
        const timeoutDuration = previousQuestionInfo.isCorrect ? 1500 : 3000;
        setTimeout(() => {
          setShowResultPopup(false);
        }, timeoutDuration);
      }
    });*/

    // This is for Streak Leaderboard
    socket.on('gameOver', (data) => {
        console.log('Game Over!', data);
        setGameOver(true);
        setGameOverData(data);
      });

    if (gameOverData && gameOverData.finalScores) {
        // Calculate team score
        const teamScore = Object.values(gameOverData.finalScores).reduce((sum, score) => sum + score, 0);
        
        setGameOverData({
        ...gameOverData,
        teamScore: teamScore
        });
    }

    socket.on('gameEnded', handleGameEnded);
    socket.on('gameStarted', handleGameStarted);
    socket.on('gameState', handleGameState);
    socket.on('roomError', handleRoomError);

    // Request initial game state
    socket.emit('requestGameState', { roomId });

    return () => {
      socket.off('gameUpdate');
      socket.off('gameOver');
      socket.off('gameEnded');
      socket.off('gameStarted');
      socket.off('gameState');
      socket.off('roomError');
    };
  }, [socket, roomId]);

  const handleGameStarted = (initialState) => {
    console.log('Game started with initial state:', initialState);
    setGameState(initialState);
    setError(null);
  };

  const handleGameUpdate = (updatedState) => {
    console.log('Received game update:', updatedState);
    setGameState(updatedState);
    setError(null);
  };

  const handleGameState = (state) => {
    console.log('Received game state:', state);
    setGameState(state);
    setError(null);
  };

  const handleGameEnded = (result) => {
    console.log('Game ended:', result);
    // You can update the game state or show a game over screen
    setGameState(prevState => ({
      ...prevState,
      isGameOver: true,
      winner: result.winner,
      finalScores: result.scores
    }));
  };

  const handleRoomError = (errorMessage) => {
    console.error('Room error:', errorMessage);
    setError(errorMessage);
  };

  const handleSubmitAnswer = (playerName, answer) => {
    console.log('Submitting answer:', answer, 'for player:', playerName);
    socket.emit('submitAnswer', { roomId, playerName, answer: parseInt(answer, 10) });
  };

  console.log('Current game state:', gameState);

  if (!gameState) return <div>Loading game... (Room ID: {roomId}, Player: {playerName})</div>;

  // Render GameOverScreen when the game is over. This is for streak leaderboard
  if (gameOver && gameOverData) {
    return (
      <GameOverScreen 
        currentPlayers={gameState.players.map(p => p.name)}
        score={gameOverData.teamScore}
        totalQuestions={10}
        leaderboard={gameOverData.leaderboard}
        currentPairRanking={gameOverData.currentPairRanking}
        streakImproved={gameOverData.streakImproved}
      />
    );
  }

  return (
    <div className="game">
        {showResultPopup && previousQuestionInfo && (
        <div className="popup-overlay">
          <div className={`result-popup ${previousQuestionInfo.isCorrect ? 'correct' : 'incorrect'}`}>
            {previousQuestionInfo.isCorrect ? (
            <>
                <h2>{correctMessages[Math.floor(Math.random() * correctMessages.length)]}</h2>
                <p className="correct-answer">
                    Correct Answer!
                  </p>
            </>
              
            ) : (
              <>
                <h3>Not quite right</h3>
                <p className="question">{previousQuestionInfo.question}</p>
                <p className="answer">
                  Correct Answer: <span className="highlight">{previousQuestionInfo.correctAnswer}</span>
                </p>
              </>
            )}
            <div className="timer-bar" style={{ animationDuration: `${popupDuration}s` }}></div>
          </div>
        </div>
      )}
      {gameState.isGameOver ? (
         <div className="game-over">
         <h2>Game Over!</h2>
         <div className="winner">
           Winner: {gameState.winner}
         </div>
         <h3>Final Scores</h3>
         <div className="final-scores">
           {Object.entries(gameState.finalScores).map(([player, score]) => (
             <div key={player} className="score-item">
               <span className="player-name">{player}</span>
               <span className="player-score">{score}</span>
             </div>
           ))}
         </div>
       </div>
      ) : (
        <>
          <div className="game-info">
            <div className="game-info-left">
              <p>Question {gameState.questionNumber} of 10</p>
            </div>
            <div className="game-info-center">
              <Scoreboard scores={gameState.scores || {}} />
              <p className="current-player">Current Player: {gameState.currentPlayer}</p>
            </div>
            <div className="game-info-right">
              <AudioControls socket={socket} roomId={roomId} playerName={playerName} />
            </div>
          </div>
          <div>
          </div>
          <div className="player-panels">
            {gameState.players.map((player, index) => (
              <PlayerPanel
                key={`${player.name}-${index}`} // This ensures uniqueness
                player={player}
                score={gameState.scores[player.name] || 0}
                isCurrentPlayer={player.name === gameState.currentPlayer}
                questionStatus={player.questionStatus || []}
                onSubmitAnswer={(answer) => handleSubmitAnswer(player.name, answer)}
                isOwnPanel={player.name === playerName}
                currentQuestion={gameState.currentQuestion}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Game;