import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Game from './components/Game';
import './theme.css';

const socket = io(process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001');

function App() {
  const [roomId, setRoomId] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [gameState, setGameState] = useState('idle');
  const [error, setError] = useState('');
  const [playerCount, setPlayerCount] = useState(0);
  const [isHost, setIsHost] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    socket.on('roomCreated', ({ roomId }) => {
      setRoomId(roomId);
      setGameState('waiting');
      setPlayerCount(1);
      setIsHost(true);
    });

    socket.on('roomJoined', ({ roomId, playerCount }) => {
      setRoomId(roomId);
      setGameState('waiting');
      setPlayerCount(playerCount);
    });

    socket.on('playerCountUpdated', ({ playerCount }) => {
      setPlayerCount(playerCount);
      if (playerCount === 2) {
        setGameState('ready');
      }
    });

    socket.on('gameStarted', () => {
      console.log('Game started'); // Add this line for debugging
      setGameState('playing');
    });

    socket.on('roomError', (errorMessage) => {
      setError(errorMessage);
    });

    // Request initial leaderboard data
    socket.on('connect', () => {
      console.log('Requesting leaderboard data');
      socket.emit('requestLeaderboard');
    });

    socket.on('leaderboardData', (leaderboardData) => {
      console.log('Leaderboard data received');
      setLeaderboard(leaderboardData);
    });

    return () => {
      socket.off('roomCreated');
      socket.off('roomJoined');
      socket.off('playerCountUpdated');
      socket.off('gameStarted');
      socket.off('roomError');
      socket.off('leaderboardData');
    };
  }, []);

  const createRoom = () => {
    if (playerName.trim() === '') {
      setError('Please enter your name');
      return;
    }
    socket.emit('createRoom', { hostName: playerName });
    setError('');
  };

  const handleRoomIdChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    setRoomId(upperCaseValue);
  };

  const joinRoom = () => {
    if (roomId.trim() === '' || playerName.trim() === '') {
      setError('Please enter both room code and your name to join the game');
      return;
    }
    socket.emit('joinRoom', { roomId, playerName });
    setError('');
  };

  const startGame = () => {
    console.log('Emitting startGame event'); // Add this line for debugging
    socket.emit('startGame', { roomId });
  };

  return (
    <div className="App">
      <h1 className="title">Playto Math!</h1>
      {gameState === 'idle' && (
        <div className="game-setup">
          <div className="game-rules">
            <h3>Play Math together with your friends!</h3>
            <ul>
              <li>2 Players in a Team. Each player gets 5 questions.</li>
              <li>Players can see each other's questions and can help each other.</li>
              <li>Answer all 10 questions correctly to win a point for your team.</li>
              <li>Teams with highest score win!</li>
            </ul>
          </div>
          <div className="input-container">
            <div className="input-group">
              <label htmlFor="playerName">Your Name</label>
              <input
                id="playerName"
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter your name"
              />
              <button className="btn create" onClick={createRoom}>New Game</button>
            </div>
            <div className="input-group">
              <label htmlFor="roomId">Game Code</label>
              <input
                id="roomId"
                type="text"
                value={roomId}
                onChange={handleRoomIdChange}
                placeholder="Enter code"
              />
              <button className="btn join" onClick={joinRoom}>Join Game</button>
            </div>
          </div>
      
          {error && <p className="error">{error}</p>}

          <div className="leaderboard">
          <h2>Leaderboard</h2>
          {leaderboard.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Players</th>
                  <th>Streak</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((entry, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                      <td>{entry.players.join(' & ')}</td>
                      <td>{entry.streakScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No leaderboard data available</p>
          )}
        </div>
        </div>
      )}
      {(gameState === 'waiting' || gameState === 'ready') && (
        <div className="waiting-room">
          <h2>Waiting Room</h2>
          {isHost && gameState != 'ready' && (
              <><div className="quest-code">
              <p>Your Game Code:</p>
              <div className="code-display">{roomId}</div>
              <p className="share-message">Share this code with your friend to join the game!</p>
              </div>
              </>
          )}
          
          <p className="player-count">Players in the room: <span>{playerCount}/2</span></p>
          {gameState === 'ready' ? (
            isHost ? (
              <><div className="quest-code">
              <p>Your Game Code:</p>
              <div className="code-display">{roomId}</div>
              <p className="share-message">Share this code with your friend to join the game!</p>
              </div>
              <button className="btn start" onClick={startGame}>Start Game</button>
              </>
            ) : (
              <p className="waiting-message">Waiting for the host to start the game...</p>
            )
          ) : (
            <div className="loading-animation">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
        </div>
      )}
      {gameState === 'playing' && (
        <Game
          socket={socket}
          roomId={roomId}
          playerName={playerName}
          isHost={isHost}
        />
      )}

    </div>
  );
}

export default App;