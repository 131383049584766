import React, { useState } from 'react';
import '../theme.css';

function PlayerPanel({ player, score, isCurrentPlayer, questionStatus, onSubmitAnswer, isOwnPanel, currentQuestion }) {
  const [answer, setAnswer] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitAnswer(answer);
    setAnswer('');
  };

  return (
    <div className={`player-panel ${isCurrentPlayer ? 'current-player' : ''}`}>
      <h3>{player.name}</h3>
      <div className="question-status">
        {questionStatus.map((status, index) => (
          <div 
            key={index} 
            className={`status-circle ${status}`}
            title={`Question ${index + 1}: ${status}`}
          ></div>
        ))}
      </div>
      {isCurrentPlayer && (
        <div className="current-question">
          <h4>Current Question:</h4>
          <p>{currentQuestion || 'Waiting for question...'}</p>
        </div>
      )}
      {isCurrentPlayer && isOwnPanel && (
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Your answer"
            required
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}

export default PlayerPanel;