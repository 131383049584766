import React from 'react';
import '../theme.css';

const GameOverScreen = ({ 
  currentPlayers, 
  score, 
  totalQuestions, 
  leaderboard, 
  currentPairRanking, 
  streakImproved 
}) => {
  return (
    <div className="game-container game-over">
      
      <div className={`streak-message ${streakImproved ? 'improved' : 'ended'}`}>
      {streakImproved ? (
          <>
            <h3>🏆 Congratulations! Your streak score improved!</h3>
          </>
        ) : (
          <>
            <h3>😢 Your streak ended. Try again!</h3>
          </>
        )}
      </div>

      <div className="leaderboard-container">
        <h3>Leaderboard</h3>
        <table className="leaderboard">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Players</th>
              <th>Streak</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((pair, index) => (
              <tr key={index} className={currentPairRanking === index + 1 ? 'current-pair' : ''}>
                <td>{index + 1}</td>
                <td>{pair.players.join(' & ')}</td>
                <td>{pair.streakScore}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <p className="current-ranking">Your team's ranking: <span className="highlight">{currentPairRanking}</span></p>
      
      <button className="btn play-again-btn" onClick={() => window.location.reload()}>Play Again</button>
    </div>
  );
};

export default GameOverScreen;